import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, useMap, useMapEvents, GeoJSON, Polyline, Popup, Polygon, ImageOverlay, Marker } from 'react-leaflet';
import styles from './GoogleMap.module.scss';
import { appConfig } from '~/Config/AppConfig';
import L, { setOptions } from 'leaflet';
import proj4 from 'proj4';
import TabsMap from '../TabsMap';
import Folder from '../Folder';
import SettingNote from '../SettingNote';
import ListFile from '../ListFile';
import PopupMenu from '../Controls/PopupMenu';
import Solution from '../Solution';
import FormSolution from '../FormSolution';
import CrossSection from '../CrossSection';
import className from 'classnames/bind';
import User from '../User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faSliders, faFile, faHammer, faChartLine, faUsers, faMinus, faPlus, faEarth, faFileWord, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import CrossSectionAPI from '~/Api/CrossSection';
import UserApi from '~/Api/User';
import FileAPI from '~/Api/File';
import jsonTinh from '~/Assets/Json/diaphantinh.json';
import FileCross from '~/Api/FileCross';
import * as turf from '@turf/turf';
import ListDocuments from '~/Components/ListDocuments';

const css = className.bind(styles);

// function changePostion(value) {
//     if (value[0] > 1000.0 && value[0] > 1000.0) {
//         // Định nghĩa biến đổi tọa độ từ EPSG:3857 sang EPSG:4326 sử dụng UTM 48N
//         proj4.defs(
//             'EPSG:3857',
//             '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs',
//         );
//         proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
//         proj4.defs('EPSG:32648', '+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs');
//         const toCoords = proj4('EPSG:3857', 'EPSG:32648', value);
//         const finalCoords = proj4('EPSG:32648', 'EPSG:4326', toCoords);
//         return [finalCoords[1], finalCoords[0]];
//     } else {
//         return [value[1], value[0]];
//     }
// }

function GoogleMap({ layer, latlng, refresh, listIdFile, isLogin, userId, openMap, isCross, bdld }) {
    const [openGroupButton, setOpenGroupButton] = useState(false);
    const [isBlinking, setIsBlinking] = useState(true);
    const [position, setPosition] = useState([]);
    const [imageFeature, setImageFeature] = useState([]);
    const [videoFeature, setVideoFeature] = useState([]);
    const [openInformation, setOpenInformation] = useState(false);
    const [openDocuments, setOpenDocuments] = useState(false);
    const [idFeature, setFeatureId] = useState(0);
    const [openMedia, setOpenMedia] = useState(false);
    const [shouldFlyTo, setShouldFlyTo] = useState(false);
    const [isOpenSolution, setIsOpenSolution] = useState(false);
    const [positionPopupMenu, setPositionPopupMenu] = useState(null);
    const [closePopupMenu, setClosePopupMenu] = useState(null);
    const [scale, setScale] = useState('50 km');
    const [feet, setFeet] = useState('30 mi');
    const mapRef = useRef(null);
    const [openFolder, setOpenFolder] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const [openCross, setOpenCross] = useState(false);
    const [openSolution, setOpenSolution] = useState(false);
    const [openListFile, setOpenListFile] = useState(false)
    const [openNote, setOpenNote] = useState(false);
    const [geojsonData, setGeojsonData] = useState([]);
    const [lineCross, setLineCross] = useState([]);
    const [tenCT, setTenCT] = useState("");
    const [chieudai, setChieuDai] = useState(0);
    const [openUpload, setOpenUpload] = useState(false);
    const [zoomValue, setZoomValue] = useState(0);
    const [listCrossSection, setListCrossSection] = useState([]);
    const [location, setLocation] = useState('');
    const [year, setYear] = useState('');
    const [keyNumber, setKeyNumber] = useState('');
    const [startPosition, setStartPosition] = useState('');
    const [lastPosition, setLastPosition] = useState('');
    const [time, setTime] = useState('');
    const [drawCoordinates, setDrawCoordinates] = useState([]);
    const [dienTich, setDienTich] = useState('');
    const [isDrawnDienTich, setIsDrawnDienTich] = useState(false);
    const [khoangCach, setKhoangCach] = useState('');
    const [isDrawnKhoangCach, setIsDrawnKhoangCach] = useState(false);
    const [mousePosition, setMousePosition] = useState({ lat: 0, lng: 0 });
    const [page, setPage] = useState(0);
    const [coordinates, setCoordinates] = useState([]);
    const isMounted = useRef(false);
    const [openLS, setOpenLS] = useState(false);

    // useEffect(() => {
    //     isMounted.current = true;

    //     return () => {
    //         isMounted.current = false;
    //     };
    // });

    const handleOpenInformation = (event) => {
        setOpenInformation(event);
    };

    const handleCloseInformation = (event) => {
        setOpenInformation(event);
    };

    const handleOpenDocuments = (event) => {
        setOpenDocuments(event);
    };

    const handleNext = () => {
        if(page<2){
            setPage(page+1);
        }
    }

    const handlePrev = () => {
        if(page>0){
            setPage(page-1);
        }
    }

    const MouseMoveHandler = () => {
        useMapEvents({
        mousemove: (e) => {
            setMousePosition({
                lat: e.latlng.lat,
                lng: e.latlng.lng
            });
        }
        });
    
        return null;
    };

    const handleOnClickMap = (e) => {
        if(isDrawnDienTich){
            const clickedCoordinates = e.latlng;
            setDrawCoordinates([...drawCoordinates, [clickedCoordinates.lat, clickedCoordinates.lng]]);
            const array = drawCoordinates;
            array.push([clickedCoordinates.lat, clickedCoordinates.lng],drawCoordinates[0]);
            setDienTich('Diện tích: '+`${array.length>3?(turf.area(turf.polygon([array]))*3.6).toLocaleString():''}`+' m');
        }
        if(isDrawnKhoangCach){
            const clickedCoordinates = e.latlng;
            setDrawCoordinates([...drawCoordinates, [clickedCoordinates.lat, clickedCoordinates.lng]]);
            const array = drawCoordinates;
            array.push([clickedCoordinates.lat, clickedCoordinates.lng]);
            setKhoangCach('Khoảng cách: '+`${functionDistance(array).toLocaleString()}`+' m');
        }   
    };

    const functionDistance = (drawCoordinates) => {
        let distance = 0;
        for (let i = 0; i < drawCoordinates.length - 1; i++) {
            const point1 = L.latLng(drawCoordinates[i]);
            const point2 = L.latLng(drawCoordinates[i + 1]);
            distance += point1.distanceTo(point2);
        }

        return distance;
    };

    useEffect(() => {
        if(isDrawnDienTich==false){
            setDrawCoordinates([]);
        }
    },[isDrawnDienTich]);

    useEffect(() => {
        if(isDrawnKhoangCach==false){
            setDrawCoordinates([]);
        }
    },[isDrawnKhoangCach])

    const handlePopupClose = () => {
        setPosition([]);
        setImageFeature([]);
        setVideoFeature([]);
        setOpenInformation(false);
        setFeatureId(0);
        setOpenMedia(false);
    };
    
    const [numberData, setNumberData] = useState([]);

    useEffect(() => {
        const file = new FileAPI();
        if(listIdFile&&listIdFile.length>0&&listIdFile.length>numberData.length){
            const idFileAdd = listIdFile.filter(number => !numberData.includes(number));
            if (idFileAdd.length!==0) {
                idFileAdd.map(item => {
                    file.getFileGeoToBase64(item)
                    .then(res => {
                        if(res.status === 200){
                            if(res.data){
                                geojsonData.push(res.data);
                                geojsonData.sort((a, b) => b.stt - a.stt);
                                setGeojsonData(geojsonData);
                                // setGeojsonData(prevState => {
                                //     const newGeojsonData = [...prevState, res.data];
                                //     newGeojsonData.sort((a, b) => a.id - b.id);
                                //     return newGeojsonData;
                                // });
                            }
                        }
                    })
                })
            }
            setNumberData(listIdFile);
        } else if(listIdFile.length<numberData.length) {
            setNumberData(listIdFile);
            const liste = numberData.filter(item => !listIdFile.includes(item));
            const newArray = geojsonData.filter(item => !liste.includes(item.id));
            setGeojsonData(newArray);   
        }
    },[listIdFile, numberData, geojsonData]);
    
    useEffect(() => {
        if (latlng) {
            setShouldFlyTo(true);
        }
    }, [latlng]);

    const MapComponent = () => {
        const map = useMap();
        mapRef.current = map;
        
        useEffect(() => {
            if(openMap){
                mapRef.current.on('popupclose', handlePopupClose);
                if (latlng && shouldFlyTo) {
                    map.flyTo(latlng, 14);
                    setShouldFlyTo(false);
                }
                return () => {
                    mapRef.current.off('popupclose', handlePopupClose);
                };
            }
        }, [latlng, map, shouldFlyTo]);

        const mapEvents = useMapEvents({
            click: handleOnClickMap,
            zoomend: () => {
                const zoom = mapRef.current.getZoom();
                setZoomValue(zoom);
                const number =
                    (156543.03392 * Math.cos((mapRef.current.getCenter().lat * Math.PI) / 180)) / Math.pow(2, zoom);
                let nearest;
                let donvi = '';
                if (number.toFixed() > 10) {
                    donvi = 'km';
                    const num = (number.toFixed() / 10).toFixed();
                    if (num < 3) {
                        nearest = 1;
                    } else if (num < 5) {
                        nearest = 3;
                    } else if (num < 10) {
                        nearest = 5;
                    } else if (num < 20) {
                        nearest = 10;
                    } else if (num < 30) {
                        nearest = 20;
                    } else if (num < 50) {
                        nearest = 30;
                    } else if (num < 100) {
                        nearest = 50;
                    } else if (num < 200) {
                        nearest = 100;
                    } else if (num < 300) {
                        nearest = 200;
                    } else if (num < 500) {
                        nearest = 300;
                    } else {
                        nearest = 500;
                    }
                } else {
                    donvi = 'm';
                    const num = number.toFixed();
                    if (num < 1) {
                        nearest = 50;
                    } else if (num < 2) {
                        nearest = 100;
                    } else if (num < 3) {
                        nearest = 200;
                    } else if (num < 5) {
                        nearest = 300;
                    } else if (num < 10) {
                        nearest = 500;
                    }
                }
                setScale(`${nearest} ${donvi}`);
                let feet = number.toFixed() * 50;
                let feetString = '';
                if (feet < 10000) {
                    feetString = `${(feet * 10).toFixed()} ft`;
                } else {
                    feetString = `${(feet / 1000).toFixed()} mi`;
                }
                setFeet(feetString);
                setClosePopupMenu(mapEvents.getZoom());
            },
        });
        return null;
    };

    const handleCloseMedia = (event) => {
        setOpenMedia(false);
    };

    const handleRefresh = (event) => {
        refresh(event);
        handlePopupClose();
    };

    const handleBlinking = (data) => {
        refresh(data);
    };

    const handleSetupPopup = (event, feature, f) => {
        const object = { id: 0, x: event.containerPoint.x, y: event.containerPoint.y };
        setPositionPopupMenu(object);
        setPosition([event.containerPoint.x, event.containerPoint.y]);
        if(feature["TEN"]!=null && feature["TEN"]!=""){
            setTenCT(feature["TEN"])
        }
        if(feature["CHIEU_DAI"]!=null && feature["CHIEU_DAI"]!=""){
            setChieuDai(feature["CHIEU_DAI"])
        }
        setCoordinates(f);
    };

    const handleSolution = (event) => {
        setIsOpenSolution(event);
    };

    const handleCloseFormSolution = (event) => {
        setIsOpenSolution(event);
    };

    const handleOpenCrossSection = () => {
        setOpenCross(true);
    };

    const handleCloseCrossSection = (data) => {
        setOpenCross(data)
    };

    const handleOpenSolution = () => {
        setOpenSolution(true);  
    };

    const handleCloseSolution = (data) => {
        setOpenSolution(data);
    };
    
    const handleOpenUser = () => {
        setOpenUser(true);
    };

    const handleCloseUser = (data) => {
        setOpenUser(data)
    };

    const handleOpenListFile = () => {
        setOpenListFile(true);
    };

    const handleCloseListFile = (data) => {
        setOpenListFile(data);
    };

    const handleOpenFolder = () => {
        setOpenFolder(true);
    };

    const handleCloseFolder = (data) => {
        setOpenFolder(data);
    };

    const handleOpenNote = () => {
        setOpenNote(true);
    };

    const handleCloseNote = (data) => {
        setOpenNote(data);
    };

    useEffect(() => {
        if(isLogin){
            handleOpenGroupButton(userId);
        }
        if(!isLogin){
            setOpenGroupButton(false);
        }
    },[isLogin]);

    const handleOpenGroupButton = (userId) => {
        const user = new UserApi();
        if(userId){
            user.getNguoiDungByUserId(userId)
            .then(response => {
                if(response.status === 200){
                    if(response.data.roleName === 'Administrator' || response.data.roleName === 'Operator'){
                        setOpenUpload(true);
                        setOpenGroupButton(true);
                    }
                }
            });
        }
    };

    const zoomPlus = () => {
        const map = mapRef.current;
        if (map) {
            map.setView(map.getCenter(), map.getZoom() + 1);
        }
    };

    const zoomMinus = () => {
        const map = mapRef.current;
        if (map) {
            map.setView(map.getCenter(), map.getZoom() - 1);
        }
    };

    const geoJsonStyle = {
        fillColor: 'transparent',
        color: 'white',
        weight: 2,
        dashArray: '10, 10'
    };

    const netDutStyle = {
        strokeWidth: 2,
        strokeDasharray: '8,4',
    };

    const clickLine = (Id) => {
        const crossSection = new CrossSectionAPI();
        crossSection.getCrossSectionByFileCrossId(Id)
        .then((respon) => {
            if(respon.status === 200){
                setListCrossSection(respon.data);
            }
        });
        const fileCross = new FileCross();
        fileCross.getFileCrossById(Id)
        .then((res) => {
            if(res.status == 200){
                let object = res.data;
                setLocation(object.location);
                setYear(object.year);
                setKeyNumber(object.keyNumber);
                setStartPosition(object.startPosition);
                setLastPosition(object.lastPosition);
                setTime(object.time);
            }
        })
    };

    const eachFeatureTinh = (f,l) => {
        const label = f.properties["ten_tinh"]?f.properties["ten_tinh"]:"";
        l.bindTooltip(label.toString(), {
            permanent: true,
            direction: 'center',
            className: css('label-tooltip-1'),
        });
    };

    const handleIsDrawnDienTich = () => {
        setIsDrawnDienTich(!isDrawnDienTich);
        if(isDrawnKhoangCach){
            setDrawCoordinates([]);
            setIsDrawnKhoangCach(!isDrawnKhoangCach);
        }
    };

    const handleIsDrawnKhoangCach = () => {
        setIsDrawnKhoangCach(!isDrawnKhoangCach);
        if(isDrawnDienTich){
            setDrawCoordinates([]);
            setIsDrawnDienTich(!isDrawnDienTich);
        }
    };

    const onEachFeature = (id,feature, layer, isLabel) => {
        const popupContent = document.createElement('div'); 
        const labelName = feature.properties["name"]?feature.properties["name"]:"";

        // if(labelName==""){
        //     ReactDOM.render(<TabsMap
        //         id={id}
        //         feature={feature.properties}
        //         openInfor={handleOpenInformation}
        //         coordinates={feature.geometry.coordinates}
        //         xy={mousePosition}
        //     />,popupContent);
        // } else {
        //     ReactDOM.render(<p style={{fontSize:'1.4rem'}}>{labelName}</p>,popupContent);
        // }
        ReactDOM.render(<TabsMap
            id={id}
            feature={feature.properties}
            openInfor={handleOpenInformation}
            coordinates={feature.geometry.coordinates}
            xy={mousePosition}
        />,popupContent);
        const popup = L.popup().setContent(popupContent);
        popup.options.maxWidth = "1000rem";
        if(isLabel!=="false"){
            const label = feature.properties["TEN"]?feature.properties["TEN"]:feature.properties["TEN_3"]?feature.properties["TEN_3"]:"";
            
            if(label!==""){
                layer.bindTooltip(label.toString(), {
                    permanent: true,
                    direction: 'center',
                    className: css('label-tooltip'),
                });
            }
            // if(labelName!=""){
            //     layer.bindTooltip(labelName.toString(), {
            //         permanent: true,
            //         direction: 'center',
            //         className: css('label-tooltip-2'),
            //     });
            // }
        }
        layer.bindPopup(popup);
        layer.on({
            contextmenu: (e) => handleSetupPopup(e, feature.properties, feature.geometry.coordinates)
        });
    };

    const handleReloadMap = () => {
        const map = mapRef.current;
        mapRef.current = map;
        map.flyTo([10.044262, 105.746589],8)
    };

    const imageBounds = [
        [9.4920524989999997, 105.1256228790000051],
        [10.8496868469999992, 106.7734271749999948]
    ];

    const imageBounds1 = [
        [9.4924486110046828, 105.1171875000000000],
        [10.8333059836424894, 106.7871093750000000]
    ];

    const imageBounds2 = [
        [9.4622855280000007, 105.0611887449999955],
        [10.9685623050000007, 106.8027280179999963]
    ];

    const imageBoundsV1 = [
        [10.3248671410000004, 105.1153850259999984],
        [10.7178316989999995, 105.4917602710000040]
    ];

    const imageBoundsV1_1 = [
        [10.4462411230000001, 105.1151121510000053],
        [10.7176060470000003, 105.3914879229999997]
    ];

    const imageBoundsV2 = [
        [10.4002824379999996, 105.4607443040000021],
        [10.5478429069999997, 105.6916479690000017]
    ];

    const imageBoundsV3 = [
        [10.2960286790000008, 106.2389124869999932],
        [10.3537325270000000, 106.4594886619999983]
    ];

    const imageBoundsV4 = [
        [10.5398926680000002, 105.3336443200000048],
        [10.7683292030000004, 105.4645968930000066]
    ];

    const handleCheckLS = (event) => {
        const isChecked = event.target.checked;
        setOpenLS(isChecked);
    };

    useEffect(() => {
        const tooltipElements = document.getElementsByClassName(css('label-tooltip'));
        if (tooltipElements.length> 0) {
            Array.from(tooltipElements).forEach((tooltipElement) => {
                tooltipElement.style.fontSize = zoomValue>9?`${zoomValue*0.15}rem`:'0rem';
            });
        }
        const tooltipElements1 = document.getElementsByClassName(css('label-tooltip-1'));
        if (tooltipElements1.length> 0) {
            Array.from(tooltipElements1).forEach((tooltipElement) => {
                tooltipElement.style.fontSize = `${zoomValue*0.15}rem`;
            });
        }
        const tooltipElements2 = document.getElementsByClassName(css('label-tooltip-2'));
        if (tooltipElements2.length> 0) {
            Array.from(tooltipElements2).forEach((tooltipElement) => {
                tooltipElement.style.fontSize = zoomValue>7?`${zoomValue*0.2}rem`:'0rem';
            });
        }
    },[zoomValue]);

    // useEffect(() => {
    //     const fileCross = new FileCross();
    //     fileCross.getFileCross().then((value) => {
    //         setLineCross(value.data);
    //     });

    //     const interval = setInterval(() => {
    //         setIsBlinking((x) => !x);  
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // },[]);

    useEffect(() => {
        isMounted.current = true;
        const fileCross = new FileCross();
        fileCross.getFileCross().then((value) => {
            setLineCross(value.data);
        });

        const interval = setInterval(() => {
            setIsBlinking((x) => !x);  
        }, 1000);

        const handleCancelFunctin = (e) => {
            // e.preventDefault();
            setDrawCoordinates([]);
            setIsDrawnKhoangCach(false);
            setDrawCoordinates([]);
            setIsDrawnDienTich(false);
        };

        document.addEventListener('contextmenu', handleCancelFunctin)
        return () => {
            document.removeEventListener('contextmenu', handleCancelFunctin);
            clearInterval(interval);
            isMounted.current = false;
        };
    },[]);

    useEffect(() => {
        const tooltipElements = document.getElementsByClassName(css('warning'));
        if(isBlinking){
            if (tooltipElements.length> 0) {
                Array.from(tooltipElements).forEach((tooltipElement) => {
                    tooltipElement.style.opacity = '0';
                });
            }
        } else {
            if (tooltipElements.length> 0) {
                Array.from(tooltipElements).forEach((tooltipElement) => {
                    tooltipElement.style.opacity = '1';
                });
            }
        }
    },[isBlinking]);

    return (
        <>
            <MapContainer
                center={[10.044262, 105.746589]}
                zoom={8}
                minZoom={5}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ minHeight: '100vh', width: '100%' }}
                attributionControl={false}
                ref={mapRef}
            >
            <TileLayer
                url={layer}
                key={appConfig.GOOGLE_API_KEY}
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            {isDrawnDienTich&&(<Polygon positions={drawCoordinates} color='orange'/>)}
            {isDrawnKhoangCach&&(drawCoordinates.length>2?<Polyline positions={drawCoordinates} color='orange'/>:<Polygon positions={drawCoordinates} color='orange'/>)}
            <GeoJSON data={jsonTinh} style={geoJsonStyle} onEachFeature={(f,l) => eachFeatureTinh(f,l)}/>
            {isCross&&lineCross&&lineCross.length>0?
                lineCross.map((x) => {             
                    const startPoint = x.startPosition.split(';').map(parseFloat);
                    const lastPoint = x.lastPosition.split(';').map(parseFloat);
                    return (<Polyline positions={[startPoint,lastPoint]} color='blue' eventHandlers={{
                        click: () => {
                            clickLine(x.id);
                            },
                        }}>
                        <Popup className={css('popup')}>
                            <div className={css('background-title')}>
                                <div className={css('title')}>MẶT CẮT</div>
                            </div>
                            <div className={css('cross-section')}>
                                <div className={css('info')}>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Năm khảo sát</p>
                                        <input className={css('info-item-input')} value={year} readOnly/>
                                    </div>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Địa danh</p>
                                        <input className={css('info-item-input')} value={location} readOnly/>
                                    </div>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Số hiệu</p>
                                        <input className={css('info-item-input')} value={keyNumber} readOnly/>
                                    </div>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Thời gian cập nhật</p>
                                        <input className={css('info-item-input')} value={time} readOnly/>
                                    </div>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Vị trí điểm đầu</p>
                                        <input className={css('info-item-input')} value={startPosition} readOnly/>
                                    </div>
                                    <div className={css('info-item')}>
                                        <p className={css('info-item-title')}>Vị trí điểm cuối</p>
                                        <input className={css('info-item-input')} value={lastPosition} readOnly/>
                                    </div>  
                                </div>
                                <div className={css('table-container')}>
                                    <table className={css('table')}>
                                        <thead className={css('thead')}>
                                            <tr>
                                                <th>Khoảng cách(m)</th>
                                                <th>Cao trình(m)</th> 
                                            </tr>
                                        </thead>
                                        <tbody className={css('tbody')}>
                                            {listCrossSection && listCrossSection.map((r) => 
                                                <tr>
                                                    <td>{r.width}</td>
                                                    <td>{r.depth}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <LineChart width={500} height={300} data={listCrossSection}>
                                        <Line
                                            type="monotone"
                                            dataKey="depth"
                                            stroke="#8884d8"
                                            strokeWidth={2}
                                            name='Cao trình (m)'
                                        />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip allowEscapeViewBox={{ AllowInDimension: { x: false } }}
                                            labelFormatter={() => ''}
                                            labelStyle={{ fontSize: 12 }}
                                            itemStyle={{ fontSize: 12 }} />
                                        <XAxis dataKey="width" tick={{ fontSize: 12 }}/>
                                        <YAxis tick={{ fontSize: 12 }} />
                                    </LineChart>
                                </div>
                            </div>
                        </Popup>
                    </Polyline>);
                }):null
            }
            {geojsonData.map((x, idx) => {
                if(listIdFile.includes(x.id)){
                    const geoJsonObject = JSON.parse(x.json);
                    const pointToLayer = (feature, latlng) => {
                        if(x.icon){
                            const icon = L.icon({
                                iconUrl: `${x.icon}`,
                                iconSize: [32, 32],
                                iconAnchor: [16, 16],
                            });
                            return L.marker(latlng, { icon });
                        }
                    };
                    return <GeoJSON key={'map'+idx} data={geoJsonObject} className={css(x.isWarning=="true"?"warning":"")} style={x.type===1?netDutStyle:''} color={x.color} onEachFeature={(f,l) => onEachFeature(x.id,f,l, x.isLabel)} pointToLayer={pointToLayer}/>;  
                } 
            })}
        <MouseMoveHandler/>
        {bdld==='1' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/aaaaa.png' bounds={imageBounds} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='2' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_4nam.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/lengd.jpg' className={css('img_legend_1')}/>
        </>)}
        {bdld==='3' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_2030.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/lengd.jpg' className={css('img_legend_1')}/>
        </>)}
        {bdld==='4' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_BDKH.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='5' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_BDTN.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='6' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_Dienhinh.png' bounds={imageBounds2} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='7' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_hanman.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='8' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_KTC.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='9' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_Lu1.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='10' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_Taolong.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='11' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/Dubao_Tohop.png' bounds={imageBounds1} className={css('custom-image-overlay')}/>
            <img src='/Assets/Images/legend_1nam.png' className={css('img_legend')}/>
        </>)}
        {bdld==='12' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/TDB_98_22.png' bounds={imageBounds2} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='13' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V1_98_09.png' bounds={imageBoundsV1_1} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='14' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V1_09_20.png' bounds={imageBoundsV1} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='15' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V1_20_22.png' bounds={imageBoundsV1} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='16' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V2_98_09.png' bounds={imageBoundsV2} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='17' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V2_09_20.png' bounds={imageBoundsV2} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='18' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V2_20_22.png' bounds={imageBoundsV2} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='19' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V3_98_09.png' bounds={imageBoundsV3} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='20' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V3_09_20.png' bounds={imageBoundsV3} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='21' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V3_20_22.png' bounds={imageBoundsV3} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='22' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V4_98_09.png' bounds={imageBoundsV4} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        
        {bdld==='23' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V4_09_20.png' bounds={imageBoundsV4} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        {bdld==='24' && (<>
            <ImageOverlay url='https://store.satlomekong.vn/images/V4_20_22.png' bounds={imageBoundsV4} className={css('custom-image-overlay')}/>
                <img src='/Assets/Images/Legend_Dienbien.png' className={css('img_legend')}/>
        </>)}
        <MapComponent />
        {window.innerWidth>500&&(
            <div className={css('scale')}>
                <div className={css('background-scale')}>
                    <div className={css('feet-value')}>
                        {'Độ cao: '}{feet}
                    </div>
                    <div className={css('scale-value')}>
                        {'Tỷ lệ: 1:'}{scale}
                    </div>
                </div>
            </div>
        )}
        {/* <div className={css('checkbox-longsau')}>
            <input type='checkbox' className={css('checkbox-ls')} checked={openLS} onChange={(e) => handleCheckLS(e)}/>
            <p className={css('title-ls')}>Biến động lòng dẫn sau 1 năm</p>
        </div> */}
        </MapContainer>
        {window.innerWidth > 430 && (
          <div className={css('group-button')}>
            {openGroupButton && (
                <>
                    {page!==0&&<FontAwesomeIcon icon={faChevronLeft} className={css('chevron')} onClick={handlePrev}/>}
                    {page==0 && (
                        <>
                            <button className={css('btn')} onClick={handleOpenFolder}>
                                <FontAwesomeIcon icon={faFolder}/> Thư mục
                            </button>
                            <button className={css('btn')} onClick={handleOpenNote}>
                                <FontAwesomeIcon icon={faSliders}/> Thiết lập chú thích
                            </button>
                            <button className={css('btn')} onClick={handleOpenListFile}>
                                <FontAwesomeIcon icon={faFile}/> Danh sách file
                            </button>
                        </>
                    )}
                </>
            )}
            {openGroupButton && (
                <>
                    {page==1 && (
                        <>
                            <button className={css('btn')} onClick={handleOpenUser}>
                                <FontAwesomeIcon icon={faUsers}/> Danh sách tài khoản
                            </button>
                            <button className={css('btn')} onClick={handleOpenSolution}>
                                <FontAwesomeIcon icon={faHammer}/> Danh sách giải pháp
                            </button>
                            <button className={css('btn')} onClick={handleOpenDocuments}>
                                <FontAwesomeIcon icon={faFileWord}/> Tài liệu
                            </button>
                        </>
                    )}
                    {page==2 &&(
                        <button className={css('btn')} onClick={handleOpenCrossSection}>
                            <FontAwesomeIcon icon={faChartLine}/> Mặt cắt
                        </button>      
                    )}
                    {page!==2&&<FontAwesomeIcon icon={faChevronRight} className={css('chevron')} onClick={handleNext}/>}
                </>
            )}
            </div>  
        )} 
        {window.innerWidth <= 430 && (
            <>
                {openGroupButton && (
                    <div className={css('group-button')}>
                        <button className={css('btn')} onClick={handleOpenFolder}>
                                <FontAwesomeIcon icon={faFolder}/> Thư mục
                        </button>
                        <button className={css('btn')} onClick={handleOpenNote}>
                            <FontAwesomeIcon icon={faSliders}/> Thiết lập chú thích
                        </button>
                        <button className={css('btn')} onClick={handleOpenListFile}>
                            <FontAwesomeIcon icon={faFile}/> Danh sách file
                        </button>
                        <button className={css('btn')} onClick={handleOpenUser}>
                            <FontAwesomeIcon icon={faUsers}/> Danh sách tài khoản
                        </button>
                        <button className={css('btn')} onClick={handleOpenSolution}>
                            <FontAwesomeIcon icon={faHammer}/> Danh sách giải pháp
                        </button>
                        <button className={css('btn')} onClick={handleOpenDocuments}>
                            <FontAwesomeIcon icon={faFileWord}/> Tài liệu
                        </button>
                        <button className={css('btn')} onClick={handleOpenCrossSection}>
                            <FontAwesomeIcon icon={faChartLine}/> Mặt cắt
                        </button>
                        <button className={css('hide-group-btn')} onClick={handleOpenCrossSection}>
                            <FontAwesomeIcon icon={faMinus}/>
                        </button>
                    </div>
                )}
            </>
        )}
        <div className={css('group-tool')}>
            <div className={css('btn-tool','primary')} onClick={handleReloadMap}><FontAwesomeIcon icon={faEarth} style={{width: '2rem', height: '2rem', cursor: 'Pointer', color: 'black'}}/></div>
            <div className={css('btn-tool','primary')} onClick={zoomPlus}><FontAwesomeIcon icon={faPlus} style={{width: '2rem', height: '2rem', cursor: 'Pointer', color: 'black'}}/></div>
            <div className={css('btn-tool','primary')} onClick={zoomMinus}><FontAwesomeIcon icon={faMinus} style={{width: '2rem', height: '2rem', cursor: 'Pointer', color: 'black'}}/></div>
            <div className={css('btn-tool',isDrawnDienTich?'white':'primary')} onClick={handleIsDrawnDienTich}><img src='/Assets/Images/icon_dien_tich.png' style={{width: '2rem', height: '2rem', cursor: 'Pointer'}}/></div>
            <div className={css('btn-tool',isDrawnKhoangCach?'white':'primary')} onClick={handleIsDrawnKhoangCach}><img src='/Assets/Images/icon_khoang_cach.png' style={{width: '2rem', height: '2rem', cursor: 'Pointer'}}/></div>
        </div>
        {isDrawnDienTich&&drawCoordinates.length>1&&<div className={css('group_dien_tich')}><p style={{color: 'white'}}>{dienTich}<sup>2</sup></p></div>}
        {isDrawnKhoangCach&&drawCoordinates.length>1&&<div className={css('group_dien_tich')}><p style={{color: 'white'}}>{khoangCach}</p></div>}
        {window.innerWidth>500 && (
            <>
                <div className={css('group_latlng')}><p style={{color: 'white', fontSize: '1.2rem'}}>Long: {mousePosition.lng.toFixed(5)}; Lat: {mousePosition.lat.toFixed(5)}</p></div>
            </>
        )}
        
        <Folder openButton={openFolder} closeFolder={handleCloseFolder}/>
        <SettingNote openNote={openNote} closeNote={handleCloseNote}/>
        <ListFile openFile={openListFile} closeFile={handleCloseListFile}/>
        <PopupMenu userId={userId} position={positionPopupMenu} page="GoogleMap" blinking={handleBlinking} closePop={closePopupMenu} openSolution={handleSolution} coordinates={coordinates}/>
        <Solution openSolution={openSolution} closeSolution={handleCloseSolution}/>
        <FormSolution isOpen={isOpenSolution} ten={tenCT} chieudai={chieudai} close={handleCloseFormSolution}/>
        <CrossSection openCrossSection={openCross} closeCrossSection={handleCloseCrossSection} openUpload={openUpload}/>
        <User openUser={openUser} closeUser={handleCloseUser}/>
        <ListDocuments isOpenList={openDocuments}/>
    </>);

}

export default GoogleMap;